<template>
	<div
		class=""
	>
		<Search
			v-if="program.search"
			:program="program"
			:search="item_search"
			:option="item_search_option"
			@toExcel="toExcel"

			@click="getData"
		></Search>

		<div
			class=""
		>
			<template
				v-if="items.length > 0"
			>

				<ul class="hide-pc">
					<!-- 주문 내역-->
					<li
						v-for="(item, index) in item_list"
						:key="item.uid"
						class=" bg-white mb-20 box box-shadow position-relative"
					>
						<!-- 주문일자 및 주문상세 이동 -->
						<div

							class="pa-10 justify-space-between "
						>
							<span class="">{{ item.wDate | transDate }}</span>
						</div>

						<div
							class=""
						>
							<div
								class="justify-space-between under-line-dashed pa-10"
							>
								<span>
									<span
										class="box pa-5"
										:class="'color-' + item.o_status_color"
									>{{ item.o_status_name }}</span>
								</span>
								<span class="color-blue">{{ item.order_price | makeComma }} 원</span>
							</div>

							<div class="pa-10 top-line justify-space-between ">
								<span class="font-weight-bold" :class="'color-' + item.o_status_color">{{ item.o_status_name }}</span>
								<span class="font-weight-bold">결제 금액: {{ item.order_price | makeComma }} 원</span>
							</div>
							<div
								class="pa-10 text-right"
							>
								<button
									v-if="item.is_cancel"
									class="pa-5-10 bg-red"
									@click="onCancel(item, index)"
								>결제 취소<v-icon small class="color-eee">mdi mdi-chevron-right</v-icon></button>
								<template
									v-else-if="item.o_status == 4"
								>{{ item.mDate }}</template>
							</div>
						</div>
					</li>
				</ul>

				<table class="table hide-m">
					<colgroup>
						<col width="60px" />
						<col width="auto" />
						<col width="auto" />
						<col width="auto" />
						<col width="auto" />

						<col width="auto" />
					</colgroup>
					<thead>
					<tr>
						<th>No</th>
						<th>결제 방식</th>
						<th>결제 금액</th>
						<th>충전 포인트</th>
						<th>결제일시</th>

						<th colspan="2">결제 상태</th>
					</tr>
					</thead>
					<tbody>
						<tr
							v-for="(item, index) in item_list"
							:key="'item_' + index"
						>
							<td
							>{{ item.no }}</td>
							<td
							>
								{{ item.pay_div_name }}
							</td>
							<td
							>{{ item.order_price | makeComma }} 원</td>
							<td
							>{{ item.order_point | makeComma }} P</td>
							<td
							>{{ item.wDate }}</td>
							<td
								:class="'color-' + item.o_status_color"
							>
								{{ item.o_status_name }}
							</td>
							<td>
								<button
									v-if="item.is_cancel"
									class="pa-5-10 bg-red"
									@click="onCancel(item)"
								>결제 취소<v-icon small class="color-eee">mdi mdi-chevron-right</v-icon></button>
								<template
									v-else-if="item.o_status == 4"
								>{{ item.mDate }}</template>
							</td>
						</tr>
					</tbody>
				</table>
				<Pagination
					:program="program"
					:align="'center'"
					:options="item_search"

					@click="getSearch"

					class="mt-auto pa-10"
				></Pagination>
			</template>
			<Empty
				v-else
			></Empty>
		</div>


		<iframe
			v-if="(item_cancel.pay_div == 'hecto' || item_cancel.pay_div == 'hecto_tel') && is_on_cancel"
			:src="danal_url"
			class="full-height full-width"
			style="display: none"
		></iframe>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"

			@close="is_excel = false"
		></Excel>
	</div>
</template>

<script>

import Pagination from "../../components/Pagination";
import Empty from "@/view/Layout/Empty";
import Search from "@/view/Layout/Search";
import Excel from "../../components/Excel";

export default{
	name: 'PointList'
	, components: {Excel, Search, Empty, Pagination}
	, props: ['user', 'codes', 'shop_info']
	,data: function() {
		return {
			program: {
				name: '포인트 결제 내역'
				, top: false
				, title: true
				, bottom: true
				, top_line: true
				, search: true
			}
			,item_search: {
				type: this.$route.params.type
				, page: 1
				, list_cnt: 10
				, tCnt: 0
				, sDate: this.$date.getLastDate(7,'-')
				, eDate: this.$date.getToday('-')
				, pay_div: ''
			}
			, item_search_option: {
				sDate: true
				, eDate: true
				, is_keyword: true
				, is_cnt: true
				, select: [
					{ name: '결제 방식', column: 'pay_div', items: this.$codes.point_pay_div}
				]
				, is_excel: true
			}
			,items: [

			]
			,is_modal: false
			,modal_option: {
				top: true
				,title: '주문취소'
				,content: '해당 주문상품을 취소하시겠습니까?'
				,bottom: true
			}
			,cancel_item: ''
			,item: {

			}
			,odt_step_confirm: 'step5'
			,odt_step_cancel: 'step21'
			,odt_step_cancel_confirm: 'step22'
			,odt_step_change: 'step31'
			,odt_step_return: 'step41'
			,item_cancel: {

			}
			,item_return: {

			}
			,item_confirm: {

			}
			,item_result: {

			}
			, base_url: ''
			, danal_url: ''
			, is_on_cancel: false
			, is_excel: false
			, excel_data: {
				name: '포인트 결제내역'
				,header: [
					{ key: 0, name: '결제방식', column: 'pay_div_name'}
					,{ key: 0, name: '결제금액', column: 'order_price'}
					,{ key: 0, name: '충전 포인트', column: 'order_point'}
					,{ key: 0, name: '결제일시', column: 'wDate'}
					,{ key: 0, name: '결제상태', column: 'o_status_name'}
				]
				,content: null
			}
		}
	}

	,computed: {
		item_list: function(){

			let now = this.$date.getToday('-')
			return this.items.filter((item, index) => {
				item.no = this.item_search.tCnt - (this.item_search.page * this.item_search.list_cnt - this.item_search.list_cnt) - index

				switch (item.o_status){
					default:
						item.o_status_color = "gray"
						break;
					case "1":
						item.o_status_color = "red"
						break;

					case "2":
						item.o_status_color = "green"
						if(now == item.wDate.substring(0, 10)){
							item.is_cancel = true
						}
						break
					case "3":
						item.o_status_color = "orange"
						item.is_cancel = false
						break
					case "4":
						item.o_status_color = "red"
						item.is_cancel = false
						break;
				}

				this.$codes.point_pay_div.filter( (code) => {
					if(code.code == item.pay_div){
						item.pay_div_name = code.name
					}
				})
				return item
			})
		}
	}
	,methods: {
		getData: async function(){

			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'order/getOrderChargeList'
					,data: this.item_search
				})

				if(result.success){
					this.items = result.data.result
					this.$set(this.item_search, 'tCnt', result.data.tCnt)
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch(e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getSearch: function(page){
			this.item_search.page = page
			this.getData()
		}
		, onCancel: function(item){
			console.log('onCancel', item.pay_div)
			if(confirm('해당 포인트 결제내역을 취소하시겠습니까?')){
				if(item.pay_div == 'hecto' || item.pay_div == 'hecto_tel'){
					this.getHectoCancel(item)
				}else{
					this.postCancel(item)
				}
			}
		}
		, getHectoCancel: async function(item){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: 'member/getHectoCancel'
					, data: item
				})

				if(result.success){
					this.danal_url = this.baseUrl + result.data
					this.is_on_cancel = true
					this.item_cancel = item
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postCancel: async function(item){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: 'member/postPointCancel'
					, data: item
				})

				if(result.success){
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_on_cancel = false
				this.onEvent()
			}
		}
		, cancelResult: function(e){
			// console.log('chargeResult', e)
			try {
				let domains = process.env.VUE_APP_DOMAIN.split('|')
				let t = false
				for(let i = 0; i < domains.length; i++){
					if(e.origin.indexOf(domains[i]) > -1){
						t = true
					}
				}

				if(t){

					let data = e?.data
					if(data && typeof data == 'string') {
						data = JSON.parse(data)
						// console.log(data)
						if(data.type == 'hecto') {
							console.log('cancelResult', data)
							if (data.result) {
								if(this.item_cancel.uid){
									this.postCancel(this.item_cancel)
								}
							} else {
								throw data.message
							}
						}
					}
				}
			}catch (e){
				this.$bus.$emit('notify', { type: 'error', message: e})
				this.is_on_charge = false
				this.is_on_payment = false
			}finally {
				this.is_on_confirm = false
			}
		}
		, onEvent: function(){
			window.removeEventListener('message', this.cancelResult)
			setTimeout( () => {
				window.addEventListener('message', this.cancelResult)
			}, 100)
		}
		, toExcel: function(){
			this.is_excel = true
			this.excel_data.content = this.items
		}
	}
	,created() {
		this.$bus.$emit('onLoad', this.program)
		this.getData()
		this.item_search.type = this.$route.params.type
		this.baseUrl = this.$danal_url
		this.onEvent()
	}
	,watch: {
		$route(){
		}
	}
}
</script>
<style>
.pdt-img img { width: 100% }

.tab .on { background-color: #333; color: #bbb}
</style>